// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$category._index/route.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$category._index/route.tsx");
  import.meta.hot.lastModified = "1734440168066.194";
}
// REMIX HMR END

import SideForm from "@/components/forms/SideForm";
import Posts from "@/components/Posts";
import { client } from "@/sanity/client";
import { POSTS_SLUG_QUERY } from "@/sanity/queries";
import { json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
export async function loader({
  params
}) {
  const items = await client.fetch(POSTS_SLUG_QUERY, {
    slug: params.category
  });
  return json({
    items
  });
}
function CategoryIndex() {
  _s();
  const {
    items
  } = useLoaderData();
  return <div className="grid lg:grid-cols-3 grid-cols-2 gap-5">
      <div className="col-span-2 lg:col-span-2">
        <Posts posts={items} />
      </div>
      <div className="h-screen lg:col-span-1 mt-24">
        <SideForm />
      </div>
    </div>;
}
_s(CategoryIndex, "QTcMLrwv6qbqQLxz/9EAbv4V7kM=", false, function () {
  return [useLoaderData];
});
_c = CategoryIndex;
export default CategoryIndex;
var _c;
$RefreshReg$(_c, "CategoryIndex");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;